import couponItem from '../../../components/couponItem.vue' //优惠券
export default {
    name: 'pay',
    components: {
        couponItem
    },
    data() {
        return {
            type: '',
            orderInfo: {},
            paymentData: {},
            is_show: false,
            is_pt: false,
            show: true
        }
    },
    created() {
        document.title = "支付";
        this.is_pt = this.$route.query.is_pt ? true : false;
        this.type = this.$route.query.type;
        this.createOrder();
    },
    methods: {
        //更新时间函数
        upTimer() {
            this.orderInfo.Countdown = this.$countdown(this.orderInfo.order_time);
            setTimeout(() => {
                this.upTimer();
            }, 1000);
        },
        //创建订单
        createOrder() {
            let data = {
                ticket_id: this.$route.query.id,
            };
            this.$route.query.date ? data.ticket_day = this.$route.query.date : '';
            this.$route.query.coupon_id && !this.is_pt ? data.member_coupon_id = this.$route.query.coupon_id : '';
            this.$route.query.group_ticket_id ? data.group_ticket_id = this.$route.query.group_ticket_id : '';
            this.$route.query.group_order_id ? data.group_order_id = this.$route.query.group_order_id : '';
            this.$UtilsApi.activeCheckout(data).then(res => {
                if (!res.is_login) {
                    localStorage.setItem('login_data', JSON.stringify({
                        name: this.type == 'weeks' ? 'weeks' : 'single',
                        id: this.$route.query.id
                    }));
                    window.location.replace('//paradise.h5.airland100.com/#/login');
                };
                if (res.data.code == 0) {
                    res.data.data.order_info['Countdown'] = '';
                    this.orderInfo = res.data.data.order_info;
                    this.paymentData = JSON.parse(res.data.data.payment);
                    this.show = false;
                    this.upTimer(); //调用倒计时函数
                    console.log(this.paymentData);
                } else {
                    if (res.data.msg) {
                        if (res.data.msg == '已经参与此次团购') {
                            this.$dialog.alert({
                                title: res.data.msg,
                                message: '点击确认前往支付',
                            }).then(() => {
                                this.$router.push({
                                    name: 'ptOrderInfo',
                                    query: {
                                        id: res.data.data[0].order_id
                                    }
                                });
                            });
                        } else {
                            this.$dialog.alert({
                                title: res.data.msg,
                                message: '点击按钮返回上一页',
                            }).then(() => {
                                this.$router.go(-1);
                            });
                        };
                    }
                }
            });
        },
        // 支付
        payment() {
            let _this = this;
            if (typeof WeixinJSBridge == 'undefined') return
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": _this.$appId, //公众号ID，由商户传入    
                "timeStamp": this.paymentData.timestamp, //时间戳，自1970年以来的秒数    
                "nonceStr": this.paymentData.nonceStr, //随机串    
                "package": this.paymentData.package,
                "signType": this.paymentData.signType, //微信签名方式：    
                "paySign": this.paymentData.paySign //微信签名
            }, function(res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    var data = {
                        type: 'ok',
                        order_id: _this.orderInfo.id,
                        price: _this.orderInfo.price
                    };
                    _this.$route.query.is_pt ? data.is_pt = 1 : '';
                    _this.$router.replace({
                        name: 'payState',
                        query: data
                    })

                } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                    var data = {
                        type: 'no',
                        order_id: _this.orderInfo.id,
                        price: _this.orderInfo.price
                    };
                    _this.$route.query.is_pt ? data.to = 1 : '';
                    _this.$route.query.is_pt ? data.is_pt = 1 : '';
                    _this.$router.replace({
                        name: 'payState',
                        query: data
                    })

                } else {
                    var data = {
                        type: 'qx',
                        order_id: _this.orderInfo.id,
                        price: _this.orderInfo.price
                    };
                    _this.$route.query.is_pt ? data.to = 1 : '';
                    _this.$route.query.is_pt ? data.is_pt = 1 : '';
                    _this.$router.replace({
                        name: 'payState',
                        query: data
                    })
                }
            });
        }

    },
}